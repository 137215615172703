import React from 'react'

import Layout from "../components/layout"

export default function Template({data}) {
  const {markdownRemark: post} = data
  
  return (
    <Layout>
      <h1>{post.frontmatter.title}</h1>
      <div style={{
        flexGrow: 1,
        position: "relative"
      }}
      dangerouslySetInnerHTML={{__html: post.html}} />
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path} }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
